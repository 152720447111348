<script>

export default {
  async created() {
    await this.loadShare()
  },
  data() {
    return {
      element: {},
      profile: {},
    }
  },
  name: "Show",
  methods: {
    async loadShare() {
      this.element = await this.$api.findShare(this.$route.params.slug)
      if (this.element.id === '') {
        this.go(`/notfound`)
      } else {
        console.log(this.element)
        this.profile = await this.$api.loginWithToken(this.element.token)
        this.$store.commit('auth/setUserAttributes', this.profile.attributes)
        this.$store.commit('auth/setAuthCode', btoa(`${this.element.token}`))
        this.go('/' + this.element.id)
      }
    },
  },
  inject: [
    '$api',
    '$helper',
  ],
}

</script>
