<template></template>

<script>
  export default {
    created() {
      this.$store.commit('auth/setAuthCode', null)
      this.$store.commit('auth/setUser', null)
      this.$store.commit('auth/setUserId', null)
      this.$store.commit('auth/setAuthToken', null)

      this.$router.push(this.$route.params.slug
          ? `/${this.$route.params.slug}`
          : '/auth/login')
    }
  }
</script>